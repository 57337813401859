window.google = window.google || {};

google.shoppingprocessevents = {
  constants: {
    addToCartButtonClass: '.tag-manager-add-to-cart-button',
    onlineDealerDropdownClass: '.tag-manager-online-dealer',
    onlineDealerDropdownEvent: 'dealerChoice',
    printShoppinglistLinkClass: '.tag-manager-print-shoppingcart',
    printShoppinglistLinkEvent: 'printCartList',
    findADealerLinkClass: '.tag-manager-find-a-dealer',
    findADealerLinkEvent: 'findDealerCartList',
    emailShoppingListLinkClass: '.tag-manager-email-shopping-list',
    emailShoppingListLinkEvent: 'emailCartList',
    saveShoppingListLinkClass: '.tag-manager-save-shopping-list',
    saveShoppingListLinkEvent: 'saveCartList',
    deleteItemFromCart: '#pnlCartArea .btn-delete',
    continueToCheckoutFromCart: '#libProceedCheckout',
    quantityBox: '#tbProductQuantity',
  },

  setup: function () {
    try {
      google.shoppingprocessevents.addOnClickEventToAddToCartButton();
      google.shoppingprocessevents.addOnClickEventToPrintShoppinglistLink();
      google.shoppingprocessevents.addOnClickEventToFindADealerLink();
      google.shoppingprocessevents.addOnClickEventToEmailShoppingListLink();
      google.shoppingprocessevents.addOnClickEventToSaveShoppingListLink();
      google.shoppingprocessevents.addOnClickEventToDeleteItemFromCart();
      google.shoppingprocessevents.addOnClickEventToContinueToCheckout();
      google.shoppingprocessevents.addOnChangeEventToQuantity();
    } catch (error) {}
  },

  addOnClickEventToPrintShoppinglistLink: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.printShoppinglistLinkClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.addPrintShoppinglistLinkToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToFindADealerLink: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.findADealerLinkClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.addFindADealerLinkToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToAddToCartButton: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.addToCartButtonClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.addAddToCartButtonToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToEmailShoppingListLink: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.emailShoppingListLinkClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.addEmailShoppingListLinkToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToSaveShoppingListLink: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.saveShoppingListLinkClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.addSaveShoppingListLinkToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnChangeEventToQuantity: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.quantityBox).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.updateQuantityToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToDeleteItemFromCart: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.deleteItemFromCart).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.deleteItemFromCartToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToContinueToCheckout: function () {
    try {
      document.querySelectorAll(google.shoppingprocessevents.constants.continueToCheckoutFromCart).forEach((item) => {
        item.addEventListener('click', () => {
          google.shoppingprocessevents.continueToCheckoutToDataLayer();
        });
      });
    } catch (error) {}
  },

  addFindADealerLinkToDataLayer: function (link) {
    try {
      var values = google.shoppingprocessevents.getShoppingButtonValues(
        link,
        google.shoppingprocessevents.constants.findADealerLinkEvent
      );
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addPrintShoppinglistLinkToDataLayer: function (link) {
    try {
      var values = google.shoppingprocessevents.getShoppingButtonValues(
        link,
        google.shoppingprocessevents.constants.printShoppinglistLinkEvent
      );
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addEmailShoppingListLinkToDataLayer: function (link) {
    try {
      var values = google.shoppingprocessevents.getShoppingButtonValues(
        link,
        google.shoppingprocessevents.constants.emailShoppingListLinkEvent
      );
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addSaveShoppingListLinkToDataLayer: function (link) {
    try {
      var values = google.shoppingprocessevents.getShoppingButtonValues(
        link,
        google.shoppingprocessevents.constants.saveShoppingListLinkEvent
      );
      google.tagmanager.add(values);
    } catch (error) {}
  },

  deleteItemFromCartToDataLayer: function (link) {
    var pimid = link.siblings('.hidden.pimid').dataset.pimid;

    var product = {
      id: pimid,
      name: 'name',
      price: '26.10',
      brand: 'Boss',
      category: 'Men/Clothing/T-Shirts',
      variant: 'black',
      quantity: 'TODO',
    };
    try {
      google.tagmanager.events.removeFromCart(product);
    } catch (error) {}
  },

  addAddToCartButtonToDataLayer: function (item) {
    try {
      google.tagmanager.events.addToCart(item);
    } catch (error) {}
  },

  addAddToCartButtonBuyersGuideToDataLayer: function (button) {
    try {
      var item = button.closest('.frg-recommendation-table');
      google.tagmanager.events.addToCartFromBuyersGuide(item);
    } catch (error) {}
  },

  continueToCheckoutToDataLayer: function () {
    try {
      var data = 'hej'; //wtf? hej?
      google.tagmanager.events.continueToCheckout(data);
    } catch (error) {}
  },

  getShoppingButtonValues: function (link, event) {
    var values = {
      nameOfButton: link.innerText.trim(),
      event: event,
    };
    return values;
  },

  updateQuantityToDataLayer: function (item) {
    var pimid = item.closest('.row').querySelector('.hidden.pimid').data.pimid;
    var newValue = item.value;
    var oldValue = item.parentElement.querySelector('#lbProductQuantity').innerHTML;
    var diff = newValue - oldValue;
    var product = {
      id: pimid,
      name: 'name',
      price: '26.10',
      brand: 'Boss',
      category: 'Men/Clothing/T-Shirts',
      variant: 'black',
      quantity: Math.abs(diff),
    };
    try {
      if (diff > 0) {
        google.tagmanager.events.addToCart(product);
      } else {
        google.tagmanager.events.removeFromCart(product);
      }
    } catch (error) {}
  },

  init: function () {
    try {
      google.shoppingprocessevents.setup();
    } catch (error) {}
  },
};

if (document.readyState === 'complete' || document.readyState === 'loaded') {
  google.shoppingprocessevents.init();
} else {
  window.addEventListener('DOMContentLoaded', () => {
    google.shoppingprocessevents.init();
  });
}
